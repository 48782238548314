import { CustomerDetailResponse } from './CustomerList';
import { ApiResponse } from './Response';

export interface WarrantyClaimedRequestDetail {
  createdAt: string,
  model: string,
  serialNo: string,
  startDate: string,
  totalSqFt: number,
  validYears: number,
  warrantySeal: number,
}

export interface WarrantyDetail {
  'id': number,
  'model': string,
  'serialNo': string,
  'startDate': string,
  'status': string,
  'tintedLocation': string,
  'totalSqFt': number,
  'updatedAt': string,
  'warrantySeal': number | null,
  'validYears': number
  claimRequest?: WarrantyClaimedRequestDetail | null,
  modifyRequest?: WarrantyClaimedRequestDetail | null,
  claimed?: [],
  old?: []
  // 'isUpdating': boolean,
  // 'updateStatus': string,
}

export function createWarrantyDetail (warrantyDetail: WarrantyDetail): WarrantyDetail {
  const startDateInUTC = window.moment.utc(warrantyDetail.startDate).toDate();
  const updatedAtInUTC = window.moment.utc(warrantyDetail.updatedAt).toDate();

  return {
    'id': warrantyDetail.id,
    'model': warrantyDetail.model,
    'serialNo': warrantyDetail.serialNo,
    'startDate': window.moment(startDateInUTC).local().format('DD/MM/YYYY'),
    'status': warrantyDetail.status,
    'tintedLocation': warrantyDetail.tintedLocation,
    'totalSqFt': warrantyDetail.totalSqFt,
    'updatedAt': window.moment(updatedAtInUTC).local().format('DD/MM/YYYY'),
    'warrantySeal': warrantyDetail.warrantySeal,
    'validYears': warrantyDetail.validYears
    // 'isUpdating': false,
    // 'updateStatus': ''
  };
}

export interface WarrantyHistory {
  'date': string,
  'description': string
}

export function createWarrantyHistory (warrantyHistory: WarrantyHistory): WarrantyHistory {
  const dateInUTC = window.moment.utc(warrantyHistory.date).toDate();
  return {
    'date': window.moment(dateInUTC).local().format('DD/MM/YYYY'),
    'description': warrantyHistory.description
  };
}
export interface WarrantyInfoResultCommon {
  'warrantyId': string|number,
  'warrantyType': string,
  'installationDate': string,
  'invoice': string,
  'warrantyDetails': Array<WarrantyDetail>,
  'warrantyHistories': Array<WarrantyHistory>,

  // #region UI
  'installationDateUi'?: string | null,
}

export interface WarrantyResultBuilding extends WarrantyInfoResultCommon {
  'adress1': string,
  'adress2': string,
  'city': string,
  'postcode': string,
  'state': string,
  'country': string,
}

export function createWarrantyResultBuilding (): WarrantyResultBuilding {
  return {
    'warrantyId': '',
    'warrantyType': '',
    'installationDate': '',
    'invoice': '',
    'warrantyDetails': [],
    'warrantyHistories': [],
    'adress1': '',
    'adress2': '',
    'city': '',
    'postcode': '',
    'state': '',
    'country': '',
    'installationDateUi': ''
  };
}

export function resetWarrantyResultBuilding (warrantyResult: WarrantyResultBuilding): void {
  warrantyResult.warrantyType = '';
  warrantyResult.installationDate = '';
  warrantyResult.invoice = '';
  warrantyResult.warrantyDetails = [];
  warrantyResult.warrantyHistories = [];
  warrantyResult.adress1 = '';
  warrantyResult.adress2 = '';
  warrantyResult.city = '';
  warrantyResult.postcode = '';
  warrantyResult.state = '';
  warrantyResult.country = '';
  warrantyResult.installationDateUi = '';
}

export function updateWarrantyResultBuilding (to: WarrantyResultBuilding, from: WarrantyResultBuilding): void {
  const installationDateInUTC = window.moment.utc(from.installationDate).toDate();

  to.warrantyId = from.warrantyId;
  to.warrantyType = from.warrantyType;
  to.installationDate = from.installationDate;
  to.invoice = from.invoice;
  to.adress1 = from.adress1;
  to.adress2 = from.adress2;
  to.city = from.city;
  to.postcode = from.postcode;
  to.state = from.state;
  to.country = from.country;
  to.installationDateUi = window.moment(installationDateInUTC).local().format('DD/MM/YYYY');

  to.warrantyDetails = [];
  from.warrantyDetails.forEach(d => {
    to.warrantyDetails.push(createWarrantyDetail(d));
  });

  to.warrantyHistories = [];
  from.warrantyHistories.forEach(h => {
    to.warrantyHistories.push(createWarrantyHistory(h));
  });
}

export interface WarrantyResultAuto extends WarrantyInfoResultCommon {
  'carRegistrationNo': string,
  'chasisNo': string,
  'colour': string,
  'make': string,
  'model': string
}

export function createWarrantyResultAuto (): WarrantyResultAuto {
  return {
    'warrantyId': '',
    'warrantyType': '',
    'installationDate': '',
    'invoice': '',
    'warrantyDetails': [],
    'warrantyHistories': [],
    'carRegistrationNo': '',
    'chasisNo': '',
    'colour': '',
    'make': '',
    'model': '',
    'installationDateUi': ''
  };
}

export function resetWarrantyResultAuto (warrantyResult: WarrantyResultAuto): void {
  warrantyResult.warrantyType = '';
  warrantyResult.installationDate = '';
  warrantyResult.invoice = '';
  warrantyResult.warrantyDetails = [];
  warrantyResult.warrantyHistories = [];
  warrantyResult.carRegistrationNo = '';
  warrantyResult.chasisNo = '';
  warrantyResult.colour = '';
  warrantyResult.make = '';
  warrantyResult.model = '';
  warrantyResult.installationDateUi = '';
}

export function updateWarrantyResultAuto (to: WarrantyResultAuto, from: WarrantyResultAuto): void {
  const installationDateInUTC = window.moment.utc(from.installationDate).toDate();
  to.warrantyId = from.warrantyId;
  to.warrantyType = from.warrantyType;
  to.installationDate = from.installationDate;
  to.invoice = from.invoice;
  to.carRegistrationNo = from.carRegistrationNo;
  to.chasisNo = from.chasisNo;
  to.colour = from.colour;
  to.make = from.make;
  to.model = from.model;
  to.installationDateUi = window.moment(installationDateInUTC).local().format('DD/MM/YYYY');

  to.warrantyDetails = [];
  from.warrantyDetails.forEach(d => {
    to.warrantyDetails.push(createWarrantyDetail(d));
  });

  to.warrantyHistories = [];
  from.warrantyHistories.forEach(h => {
    to.warrantyHistories.push(createWarrantyHistory(h));
  });
}

export interface WarrantyInfoResult {
  'buildingWarranties': {
    buildingWarranties: Array<WarrantyResultBuilding>
  },
  'autoWarranties': {
    autoWarranties: Array<WarrantyResultAuto>
  },
  customer: CustomerDetailResponse
}

export interface WarrantyInfoResponse extends ApiResponse {
  result: WarrantyInfoResult
}

export interface WarrantyDropdown {
  invoice: string,
  warrantyType: number,
  warrantyTypeDisplay: string,
  warrantyDetails: WarrantyResultBuilding | WarrantyResultAuto
}

export interface RegisterWarrantyAddress {
  address1: string,
  address2: string,
  postcode: string,
  city: string,
  state: string,
  country: string
}

export function initRegisterWarrantyAddress (): RegisterWarrantyAddress {
  return {
    'address1': '',
    'address2': '',
    'postcode': '',
    'city': '',
    'state': '',
    'country': ''
  };
}

export function resetRegisterWarrantyAddress (form: RegisterWarrantyAddress): void {
  form.address1 = '';
  form.address2 = '';
  form.postcode = '';
  form.city = '';
  form.state = '';
  form.country = '';
}

export interface RegisterWarrantyDetails {
  productId: number | null,
  warrantySealId: number | null,
  tintedLocation: string,
  totalSqFt: number | null,
  startDate: string,

  // #region UI
  startDateUi?: string | null,
  serialNo?: string | null,
  model?: string | null,
  warrantySealRunNo?: number | null
  // #endregion
}

export function initRegisterWarrantyDetails (): RegisterWarrantyDetails {
  return {
    'productId': null,
    'warrantySealId': null,
    'tintedLocation': '',
    'totalSqFt': null,
    'startDate': '',
    'startDateUi': null
  };
}

export function resetRegisterWarrantyDetails (form: RegisterWarrantyDetails):void {
  form.productId = null;
  form.warrantySealId = null;
  form.tintedLocation = '';
  form.totalSqFt = null;
  form.startDate = '';
  form.startDateUi = null;
}

export interface RegisterWarrantyWithCustomer {
  customerId: number,
  name: string,
  phone: string,
  email: string,
  ic: string,
  customerAddress: RegisterWarrantyAddress,
  invoice: string,
  installationDate: string,
  warrantyDetails: Array<RegisterWarrantyDetails>,

  // #region UI
  installationDateUi?: string | null,
  warrantyTypeChoice?: number,
  // #endregion

  // #region auto
  carRegistrationNo?: string,
  chasisNo?: string,
  make?: string,
  model?: string,
  colour?: string,
  // #endregion

  // #region building
  buildingAddress?: RegisterWarrantyAddress,
  // #endregion
  isSubmitting: boolean
}

export function initRegisterWarrantyWithCustomer (): RegisterWarrantyWithCustomer {
  return {
    'customerId': 0,
    'name': '',
    'phone': '',
    'email': '',
    'ic': '',
    'customerAddress': initRegisterWarrantyAddress(),
    'invoice': '',
    'installationDate': '',
    'installationDateUi': null,
    'warrantyTypeChoice': 2,
    'warrantyDetails': [initRegisterWarrantyDetails()],
    'carRegistrationNo': '',
    'chasisNo': '',
    'make': '',
    'model': '',
    'colour': '',
    'buildingAddress': initRegisterWarrantyAddress(),
    'isSubmitting': false
  };
}

export function resetRegisterWarrantyWithCustomer (form: RegisterWarrantyWithCustomer): void {
  form.customerId = 0;
  form.name = '';
  form.phone = '';
  form.email = '';
  form.ic = '';
  form.invoice = '';
  form.installationDate = '';
  form.installationDateUi = null;
  form.warrantyDetails = [];
  form.carRegistrationNo = '';
  form.chasisNo = '';
  form.make = '';
  form.model = '';
  form.colour = '';

  form.warrantyDetails.push(initRegisterWarrantyDetails());

  // @ts-expect-error: Ignore error explicitly. The buildingAddress will only be removed during calling api.
  resetRegisterWarrantyAddress(form.buildingAddress);
  resetRegisterWarrantyAddress(form.customerAddress);
}
